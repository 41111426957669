@import './mixins/all.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;

  @include tablet {
    font-size: 68.75%;
  }

  @include laptop {
    font-size: 75%;
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

/* INPUT FIXES */
input {
  border-radius: 0px;
}

textarea:focus,
input:focus {
  outline: none;
}

section {
  position: relative;
  z-index: 1;
  background-color: white;
  margin-top: -2px;
  padding-bottom: 2px;
}

.container__fixed-width {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

textarea:focus,
input:focus {
  outline: none;
}

.react-phone-number-input__row {
  display: flex;
  align-items: center;
  height: 100%;

  input {
    height: 100%;
    border: none;
  }
}

/* CUSTOM STYLES */
.react-phone-number-input__country {
}
/* END CUSTOM */

.react-phone-number-input__phone {
  flex: 1;
  min-width: 0;
}

.react-phone-number-input__icon {
  padding-top: 4px;
  width: 18px;
}

.react-phone-number-input__icon--international {
  padding-top: 8px;
  width: 16px;
  border: none;
}

.react-phone-number-input__error {
  display: none;
}

.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
  margin: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}

.react-phone-number-input__ext-input {
  width: 3em;
}

.react-phone-number-input__ext {
  white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em;
}

/* Styling native country `<select/>`. */

.react-phone-number-input__country--native {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100% - 18px);
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.react-phone-number-input__country-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.react-phone-number-input__country-select-arrow {
  display: block;
  content: '';
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: $white-color;
  opacity: 0.7;
  transition: color 0.1s;
}

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider {
  font-size: 1px;
  background: $white-color;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: #03b2cb;
}

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
  border-bottom: none;
}

// .react-phone-number-input__input--invalid {
//   border-color: $red-color !important;
// }

/* TOAST */
.toast-message {
  width: 95%;
  max-width: 340px;
  margin: 2.5%;

  @include tablet {
    margin: 12px;
  }

  .Toastify__toast {
    min-height: 40px;

    .Toastify__close-button--error {
      color: #721c24;
    }

    .Toastify__close-button--success {
      color: #155724;
    }
  }

  .Toastify__toast-body {
    p,
    strong {
      font-size: 1.4rem;
    }
  }

  .Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .Toastify__toast--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

/* LOADER */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  transform: scale(0.5);
  transform-origin: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $lightblue-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $lightblue-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SECOND LOADER */
.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $lightblue-color;
    border-color: $lightblue-color transparent $lightblue-color transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
