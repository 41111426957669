$nav-height: 50px;
$nav-menu-height: 65vh;
$nav-speed: 0.25s;

$nav-padding-mobile-s: 25px;
$nav-padding-mobile-m: 30px;
$nav-padding-mobile-l: 35px;
$nav-padding-tablet: 25px;
$nav-padding-laptop: 40px;
$nav-padding-laptop-l: 40px;

$padding-mobile-s: 25px;
$padding-mobile-m: 30px;
$padding-mobile-l: 35px;
$padding-tablet: 13.8vw;
$padding-laptop: 13.8vw;
$padding-laptop-l: 200px;

$p-size-mobile-s: 100vw;
$p-size-mobile-m: 100vw;
$p-size-mobile-l: 55vw;
$p-size-tablet: 45vw;
$p-size-laptop: 44vw;
$p-size-laptop-l: 1440px;

$project-size-mobile-s: calc(#{$p-size-mobile-s} - #{$padding-mobile-s});
$project-size-mobile-m: calc(#{$p-size-mobile-m} - #{$padding-mobile-m});
$project-size-mobile-l: calc(#{$p-size-mobile-l} - #{$padding-mobile-l});
$project-size-tablet: calc(#{$p-size-tablet} - #{$padding-tablet});
$project-size-laptop: calc(#{$p-size-laptop} - #{$padding-laptop});
$project-size-laptop-l: (($p-size-laptop-l - $padding-laptop-l) / 3) + 1;

// marges: 13.8%, kolommen: 11.6%, tussenruimte: 3.5% (horiz)
// veticaal: 11.11%

$vpadding-mobile-s: 60px;
$vpadding-mobile-m: 65px;
$vpadding-mobile-l: 70px;
$vpadding-tablet: 80px;
$vpadding-laptop: 90px;
$vpadding-laptop-l: 90px;

$white-color: #fff;
$red-color: #cc3333;
$blue-color: #272674;
$lightblue-color: #394697;
$error-color: #d63031;
