@import '../mixins/all.scss';

$marker-size: 25px;
$marker-speed: 0.25s;

nav {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: $nav-height;
  padding: 0 $nav-padding-mobile-s;
  background: $white-color;

  .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .logo,
    .button,
    .social,
    .linkContainer {
      position: absolute;
      z-index: 5;
      width: auto;
      transition: left $nav-speed ease-in-out, right $nav-speed ease-in-out;
    }

    .button {
      top: 0;
      left: ($nav-padding-mobile-s - ($nav-height / 2));
      height: 100%;
      width: $nav-height;
      transition: left $nav-speed ease-in-out, right $nav-speed ease-in-out, transform $nav-speed ease-in-out;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        width: 8px;
        height: 8px;
        border: 2px solid $lightblue-color;

        &:nth-child(1) {
          margin-bottom: 2px;
        }

        &:nth-child(2) {
          margin-bottom: 2px;
          border-radius: 50%;
        }
      }
    }

    .logo {
      top: 50%;
      left: 50%;
      transition: left $nav-speed ease-in-out, right $nav-speed ease-in-out, transform $nav-speed ease-in-out;
      transform: translate(-50%, -50%);
      height: ($nav-height / 2);

      svg {
        height: 100%;
      }
    }

    .social {
      top: 50%;
      right: -($nav-padding-mobile-s + 3 * 20px);
      transform: translateY(-50%);

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .icon {
        height: 16px;
        margin-left: 7px;

        svg {
          height: 100%;
        }
      }
    }

    .linkContainer {
      width: 100%;
      top: $nav-height;
      overflow: hidden;

      .links {
        padding: $nav-padding-mobile-s;
        height: $nav-menu-height;
        background: white;
        margin-top: -$nav-menu-height;
        margin-bottom: $marker-size;
        transition: margin-top $nav-speed ease-in-out;
        -webkit-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .link {
          font-size: 1.8rem;
          padding: 11px 0;

          &:nth-last-child(1) {
            padding-bottom: 70px;
          }

          &.active {
            font-weight: 500;
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: ($marker-size / 2);
          left: 50%;
          width: $marker-size;
          height: $marker-size;
          background: $white-color;
          transform: translateX(-50%) rotate(45deg);
        }
      }

      .contactContainer {
        position: absolute;
        bottom: 1.5 * $marker-size;
        left: 0;
        width: 100%;
        padding: 0 $nav-padding-mobile-s;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;

          svg {
            margin-right: 10px;
            height: 1.45rem;
          }
        }
      }
    }

    &__navOpened {
      .button {
        left: -($nav-padding-mobile-s + $nav-height);
        transform: rotate(180deg + 90deg);
      }

      .logo {
        left: $nav-padding-mobile-s;
        transform: translate(0, -50%);
      }

      .social {
        right: $nav-padding-mobile-s;
      }

      .linkContainer {
        .links {
          margin-top: 0;
        }
      }
    }
  }
}
@include mobile-m {
  nav {
    height: $nav-height;
    padding: 0 $nav-padding-mobile-m;

    .container {
      .button {
        left: ($nav-padding-mobile-m - ($nav-height / 2));
        width: $nav-height;
      }

      .logo {
        height: ($nav-height / 2);
      }

      .social {
        right: -($nav-padding-mobile-m + 3 * 20px);
      }

      .linkContainer {
        top: $nav-height;

        .links {
          padding: $nav-padding-mobile-m;
          height: $nav-menu-height;
          margin-top: -$nav-menu-height;
        }

        .contactContainer {
          bottom: 1.5 * $marker-size;
          padding: 0 $nav-padding-mobile-m;

          &__item {
            svg {
              margin-right: 25px;
              height: 1.45rem;
            }
          }
        }
      }

      &__navOpened {
        .button {
          left: -($nav-padding-mobile-m + $nav-height);
        }

        .logo {
          left: $nav-padding-mobile-m;
        }

        .social {
          right: $nav-padding-mobile-m;
        }

        .linkContainer .links {
          margin-top: 0;
        }
      }
    }
  }
}
@include mobile-l {
  nav {
    height: $nav-height;
    padding: 0 $nav-padding-mobile-l;

    .container {
      .button {
        left: ($nav-padding-mobile-l - ($nav-height / 2));
        width: $nav-height;
      }

      .logo {
        height: ($nav-height / 2);
      }

      .social {
        right: -($nav-padding-mobile-l + 3 * 20px);
      }

      .linkContainer {
        top: $nav-height;

        .links {
          padding: $nav-padding-mobile-l;
          height: $nav-menu-height;
          margin-top: -$nav-menu-height;
        }

        .contactContainer {
          bottom: 1.5 * $marker-size;
          padding: 0 $nav-padding-mobile-l;
        }
      }

      &__navOpened {
        .button {
          left: -($nav-padding-mobile-l + $nav-height);
        }

        .logo {
          left: $nav-padding-mobile-l;
        }

        .social {
          right: $nav-padding-mobile-l;
        }

        .linkContainer .links {
          margin-top: 0;
        }
      }
    }
  }
}
@include tablet {
  nav {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    padding: 0 $nav-padding-tablet;
    z-index: 10;

    -webkit-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.08);

    .container {
      justify-content: space-between;

      .button,
      .social {
        display: none;
      }

      .logo {
        position: initial;
        transform: initial;
        transition: none;
      }

      .linkContainer {
        position: initial;
        width: auto;
        height: 100%;
        overflow: visible;

        .contactContainer {
          display: none;
        }

        .links {
          height: 100%;
          margin: 0;
          padding: 0;

          display: flex;
          flex-direction: row;
          align-items: flex-end;

          transition: none;
          -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
          -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

          &::after {
            display: none;
          }

          .link {
            font-size: 1.6rem;
            padding: 0;

            &.active {
              font-weight: 300;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            height: 100%;
            padding: 0 (25px / 2);

            &.active {
              .marker {
                &__top::after {
                  bottom: -($marker-size / 2);
                }

                &__bottom::after {
                  top: -($marker-size / 2);
                }
              }
            }

            .marker {
              position: absolute;
              width: $marker-size * 2;
              height: $marker-size;
              overflow: hidden;
              pointer-events: none;

              &::after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: $marker-size;
                height: $marker-size;
                background: $white-color;
                transition: top $marker-speed ease-in-out, bottom $marker-speed ease-in-out;

                -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
                -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
              }

              &__top {
                top: -$marker-size;

                &::after {
                  bottom: -($marker-size * 1.5);
                }
              }

              &__bottom {
                bottom: -$marker-size;

                &::after {
                  top: -($marker-size * 1.5);
                }
              }
            }

            &:nth-last-child(1) {
              position: relative;
              z-index: 1;
              padding: 0 25px;
              margin-left: (25px / 2);
              color: $white-color;

              &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: -5px;
                left: 0;
                width: 100%;
                height: ($nav-height + 10);
                background: $red-color;
              }
            }
          }
        }
      }
    }
  }
}
@include laptop {
  nav {
    padding: 0 $nav-padding-laptop;
  }
}
@include laptop-l {
  nav {
    padding: 0 $nav-padding-laptop-l;
  }
}
