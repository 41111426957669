@import '../../mixins/all.scss';

.extras_header {
  margin-top: $nav-height;
  height: 50vh;
  position: relative;
  z-index: 2;

  .slider {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &_container {
      height: 100%;
      transition: transform 0.5s ease-in-out;

      .slide {
        display: inline-block;
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;

        &__image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .navigation {
    &__top {
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}
@include mobile-m {
}
@include mobile-l {
}
@include tablet {
}
@include laptop {
}
@include laptop-l {
}
