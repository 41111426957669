@import './mixins/all.scss';

.navlink {
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  color: $blue-color;
  font-size: 1.5rem;
}

.contact-text {
  font-weight: 300;
  font-size: 1.4rem;
  color: black;
  line-height: 1.9rem;
  text-decoration: none;
}

.slide-title {
  font-weight: 500;
  color: $blue-color;
  font-size: 2.8rem;
  line-height: 3rem;

  &__lightblue {
    color: $lightblue-color;
  }
}

.slide-subcontent {
  font-weight: 300;
  text-transform: uppercase;
  color: $white-color;
  font-size: 1.6rem;
  vertical-align: text-top;
}

.main-text {
  font-weight: 300;
  font-size: 1.45rem;
  color: black;
  line-height: 1.9rem;

  &__medium {
    font-weight: 500;
  }

  &__red {
    color: $red-color;
  }

  &__blue {
    color: $blue-color;
  }

  &__lightblue {
    color: $lightblue-color;
  }

  &__small-line {
    line-height: 1.7rem;
  }
}

.main-link-text {
  font-weight: 300;
  text-transform: uppercase;
  color: $lightblue-color;
  font-size: 1.6rem;
}

.highlight-text {
  color: $white-color;
  font-weight: 500;
  font-size: 1.3rem;

  @include laptop {
    font-size: 1.4rem;
  }
}

.footer-text {
  font-style: normal;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.35rem;
  color: $white-color;
  line-height: 1.8rem;
}

.footer-title {
  font-style: normal;
  text-decoration: none;
  font-weight: 500;
  font-size: 2.4rem;
  color: $white-color;
}

.small-text {
  font-size: 1rem;
  color: $white-color;
  text-decoration: none;
  line-height: 1.1rem;
  font-weight: 300;
}

.form-text {
  font-size: 16px;
  color: $lightblue-color;
  text-decoration: none;
  line-height: 17px;
  font-weight: 300;
}

.form-error {
  font-size: 1rem;
  font-style: italic;
  color: $error-color;
  text-decoration: none;
  font-weight: 300;
}

.form-notice-text {
  font-size: 1.1rem;
  text-decoration: none;
  line-height: 1.3rem;
  font-weight: 300;

  &__blue {
    color: $lightblue-color;
    font-weight: 500;
  }
}

.detail-item-text {
  font-weight: 300;
  font-size: 1.3rem;
  color: black;
  line-height: 1.4rem;

  &__medium {
    font-weight: 500;
  }

  &__red {
    color: $red-color;
  }

  &__blue {
    color: $blue-color;
  }

  &__lightblue {
    color: $lightblue-color;
  }
}

.item-title {
  font-weight: 500;
  color: $blue-color;
  font-size: 2.4rem;
  line-height: 2.8rem;

  &__lightblue {
    color: $lightblue-color;
  }
}

.extra-title {
  font-weight: 500;
  color: $blue-color;
  font-size: 2.3rem;
  line-height: 3rem;

  @include mobile-m {
    font-size: 2.7rem;
  }

  &__lightblue {
    color: $lightblue-color;
  }
}

.extra-text {
  font-weight: 300;
  font-size: 1.3rem;
  color: black;
  line-height: 1.7rem;

  &__medium {
    font-weight: 500;
  }

  &__red {
    color: $red-color;
  }

  &__blue {
    color: $blue-color;
  }

  &__lightblue {
    color: $lightblue-color;
  }

  &__small-line {
    line-height: 1.7rem;
  }
}
