.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  &.loaded {
    .image {
      opacity: 1;
    }
    .svg,
    .bg {
      opacity: 0;
    }

    &::after {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    animation: load 0.8s ease-in-out forwards infinite;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .svg,
  .image {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .image {
    opacity: 0;
  }

  .svg {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;

    svg {
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

@keyframes load {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
