@import '../../mixins/all.scss';

.content {
  padding: $vpadding-mobile-s $padding-mobile-s;

  h2 {
    margin-bottom: 25px;
  }

  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 25px;
  }
}
@include mobile-m {
  .content {
    padding: $vpadding-mobile-m $padding-mobile-m;

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}
@include mobile-l {
  .content {
    padding: $vpadding-mobile-l $padding-mobile-l;

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}
@include tablet {
  .content {
    padding: $vpadding-tablet $padding-tablet;

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}
@include laptop {
  .content {
    padding: $vpadding-laptop $padding-laptop;

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}
@include laptop-l {
  .content {
    padding: $vpadding-laptop-l $padding-laptop-l;

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}
