@keyframes sideway {
  from {
    transform: scaleY(1) translateX(0);
  }
  to {
    transform: scaleY(0.8) translateX(4px);
  }
}

@keyframes sideway-l {
  from {
    transform: scaleY(1) translateX(0) rotate(180deg);
  }
  to {
    transform: scaleY(0.8) translateX(-4px) rotate(180deg);
  }
}

@keyframes trans-sideway-l {
  from {
    transform: scaleY(1) translate(0, -50%);
  }
  to {
    transform: translate(-6px, -50%) scaleY(0.8);
  }
}

@keyframes trans-sideway-r {
  from {
    transform: scaleY(1) translate(0, -50%);
  }
  to {
    transform: translate(6px, -50%) scaleY(0.8);
  }
}
